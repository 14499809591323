import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";
import {getAllAppointments, showAllAppointmentsListView} from "./util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import CreateAppointment from "./createAppointment";
import userStore from "../../components/userManagement/userStore";
import history from "../../helper/browserHistory";


const AppointmentManagementPage = () => {
    const [allAppointments, setAllAppointments] = useState([]);
    const [appointmentId, setAppointmentId] = useState('');
    const [editClicked, setEditClicked] = useState(false);
    const [isNewAppointment, setIsNewAppointment] = useState(false);


    useEffect(() => {
        if(!userStore.isAdmin){
            history.push('/de-DE/')
        }
        getAllAppointments().then(appointments_ => {
            setAllAppointments(appointments_);
        })
    }, [])



    return (
        <>
            {editClicked ? <CreateAppointment id={appointmentId} onClose={(isEdit) => {
                    getAllAppointments().then(appointments_ => {
                        setAllAppointments(appointments_);
                        setEditClicked(isEdit);
                    })
                }} isNew={isNewAppointment}/>
                :
                <div className={'grid ui page-wrapper'} >
                    <div className={'row'}>
                        <div className={'fourteen wide column'}>
                            <Button color={'green'} floated={'right'} onClick={_ => {
                                setIsNewAppointment(true);
                                setEditClicked(true);
                            }}>Termin erstellen</Button>
                        </div>
                    </div>
                    <div className={'row centered'}>
                        <div className={'fourteen wide column'}>
                            <Table striped selectable celled>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            Titel
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Start der Veranstaltung
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Ende der Veranstaltung
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Ort
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Kontakt
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Veröffentlicht
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Plätze
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Kopieren
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Löschen
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {showAllAppointmentsListView(allAppointments, setEditClicked, setIsNewAppointment, setAppointmentId, setAllAppointments)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>}
        </>
    )
}


export default withTranslation()(AppointmentManagementPage)