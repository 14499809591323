import React, {useEffect, useState} from "react";
import config from "../config/main.config"
import languages from "../../shared/activeLanguages.json";
import {isEntryInDB} from "../../server/helper/ssrHelper";

export const regexpMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const regexPhone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

export const MAX_MOBILE_WIDTH = 787;

const DEFAULT_CMS_ITEM = {
    title: '',
    description: '',
    content: '',
    isNamespace: ''
}

const DEFAULT_BLOG_ITEM = []

const DEFAULT_FAQ_ITEM = []

const DEFAULT_ARTICLE_ITEM = {
    id: "",
    header: '',
    authorName: '',
    thumbnail: '',
    text: '',
    description: '',
    date: "",
    blogImage: '',
    seen: 0,
    blogLink: '',
    userId: ''
}

export function getDefaultCMSItem() {
    return DEFAULT_CMS_ITEM;
}

/**
 * Get CMS Item by Id
 * @param textStore
 * @param id
 * @param language
 * @returns {{isNamespace: string, description: string, title: string, content: string}}
 */
export function getCMSItemById(textStore, id, language = 'de-DE') {
    let item = DEFAULT_CMS_ITEM;

    if (textStore !== undefined && textStore[id] !== undefined) {
        item = textStore[id][language] || DEFAULT_CMS_ITEM;
    }
    return item;
}

export function getAllArticlesAtPage(store) {
    let item = DEFAULT_BLOG_ITEM;

    if (store !== undefined && store.hasOwnProperty('articles')) {
        item = store.articles.articles;
    }

    return item;
}

export function getLanguage(lang) {
    let allLanguages = languages.LANGUAGES;
    let languageToSet;
    for (let i = 0; i < allLanguages.length; i++) {
        if (allLanguages[i].languageCode === lang) {
            languageToSet = allLanguages[i]
            break;
        }
    }
    return languageToSet;
}

export function languageOptions(label, all = false) {
    let allLanguages = [];
    languages.LANGUAGES.map((language, index) => {
            if (language.isActive || all) {
                allLanguages.push({
                    index: index,
                    value: language.languageCode,
                    label: language[label]
                })
            }
            return allLanguages;
        }
    )
    return allLanguages;
}

export function currentLang(i18n, label) {
    let currentLanguage = {};
    languages.LANGUAGES.map((language, index) => {
            if (language.languageCode === i18n.language) {
                currentLanguage = {
                    index: index,
                    value: language.languageCode,
                    label: language[label]
                }
            }
        }
    )
    return currentLanguage;
}

export async function getAllDownloads() {
    const response = await fetch(config.BASE_URL + "blog/downloads");
    return await response.json()
}

export async function uploadDownload(files) {
    const formData = new FormData();
    formData.append("file", files[0]);
    const response = await fetch(config.BASE_URL + "blog/downloads", {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: formData
    });
    if (response.status >= 200 && response.status < 400) {
        return await getAllDownloads()
    } else {
        return []
    }
}

export async function deleteDownload(fileName) {
    const response = await fetch(config.BASE_URL + "blog/downloads/" + fileName, {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        }
    });
    if (await response.json()) {
        return await getAllDownloads()
    } else {
        return []
    }
}

export function initializeTextStore(textStore, id, namespace, setTextStore, setActiveLanguageOptions, setTargetLanguage, i18n) {
    if (typeof textStore[id] === "undefined") {
        isEntryInDB(id, namespace).then(text => {
            if (Object.keys(text).length !== 0) {
                setTextStore({...textStore, text});
            }
        });
    }
    setActiveLanguageOptions(languageOptions("nativeLanguage", true));
    setTargetLanguage(getLanguage(i18n.language));
}

export function updateLanguage(textStore, id, language, setTextToShow) {
    if (Object.keys(textStore).length > 0 && typeof textStore[id] !== "undefined" && typeof textStore[id][language] !== "undefined") {
        setTextToShow({
            title: textStore[id][language].title,
            description: textStore[id][language].description,
            content: textStore[id][language].content
        });
    } else {
        setTextToShow({title: '', description: '', content: ''});
    }
}

export function fetchAllImages() {
    return new Promise(res => fetch(config.BASE_URL + "images/web", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                let allImages = [];
                for (let i = 0; i < json.length; i++) {
                    if (!json[i].includes('.webp')) {
                        allImages.push({
                            link: json[i]
                        })
                    }
                }
                return res(allImages);
            });
        }
    }).catch(error => {
            console.log(error);
            if (error.status === 401) {
                window.history.replace("/admin-login");
            }
        }
    ));
}

export function getResponsiveImagePath(windowWidth) {

    let path = 'desktop/';
    if (windowWidth < 1372) {
        path = 'tablet/';
        if (windowWidth < 767) {
            path = 'mobile/';
        }
    }
    return path;
}

export function updateText(id, textToUpdate, language, ns) {
    return fetch(config.BASE_URL + "texts/", {
        method: "PUT",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
            frontendId: id,
            content: textToUpdate.content,
            title: textToUpdate.title,
            description: textToUpdate.description,
            language: language,
            namespace: ns
        })
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return true
        } else if (response.status === 401) {
            alert("Bitte melden Sie sich an");
        } else {
            alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
        }
    })
        .catch(
            error => {
                alert(error);
            }
        );
}

export function createText(id, textToCreate, language, ns) {
    return fetch(config.BASE_URL + "texts/", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({
            frontendId: id,
            content: textToCreate.content,
            title: textToCreate.title,
            description: textToCreate.description,
            language: language,
            namespace: ns
        })
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return true
            } else if (response.status === 401) {
                alert("Bitte melden Sie sich an");
            } else {
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
            }
        })
        .catch(
            error => {
                console.error(error);
                alert("Ein Fehler ist passiert bitte versuchen Sie es erneut");
            }
        );
}

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function getTokenFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.token;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
}

export function removeTokenFromStorage() {
    if (typeof window !== "undefined") {
        localStorage.removeItem(config.STORAGE_KEY);
        localStorage.removeItem(config.USER_STORAGE_KEY);
    }
}

export function setTokenLocalStorage(token) {
    if (typeof window !== "undefined") {
        let object = {
            "token": token,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.STORAGE_KEY, JSON.stringify(object));
    }
}

export function setUserToLocalStorage(user) {
    if (typeof window !== "undefined") {
        let object = {
            "user": user,
            timestamp: new Date().getTime()
        };

        localStorage.setItem(config.USER_STORAGE_KEY, JSON.stringify(object));
    }

}

export function getUserFromLocalStorage() {
    if (typeof window !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.USER_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.user;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
    return null;
}

export async function fetchMissingTextById(textStore, id, language) {
    if (typeof textStore[id] === "undefined") {
        return await fetchTextByIdAndLanguage(language, id);
    } else if (typeof textStore[id][language] !== "undefined") {
        let text = {};
        text[id] = textStore[id];
        return text;
    } else
        return {};
}

function addObjectToTexts(textObject) {
    let tempTexts = {}
    if (typeof tempTexts[textObject.frontendId] === "undefined") {
        tempTexts[textObject.frontendId] = {};
    }

    tempTexts[textObject.frontendId][textObject.language] = {
        content: textObject.content,
        title: textObject.title,
        description: textObject.description,
        isNamespace: textObject.namespace
    };
    return tempTexts
}

export function fetchTextByIdAndLanguage(lang, frontendId) {
    //console.log("fetch by language: ", lang, "id: ",frontendId)
    return fetch(config.BASE_URL + "texts/" + lang + "/" + frontendId, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    }).then(response => {
        if (response.status >= 200 && response.status < 300 && response.status !== 204) {
            return response.json().then(json => {
                return addObjectToTexts(json);
            });
        }
    }).catch(
        error => {
            console.log(frontendId)
            console.error("Error on Fetch Text: ", error);
        }
    );
}

export function UseWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({width: undefined, height: undefined});
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });

        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export function deleteFolder(name) {
    return new Promise(result => fetch(config.BASE_URL + "images/userFolder/" + name, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({name: name})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            getFolders().then(folders => {
                return result(folders);
            })
        } else {
            alert("Error deleting folder");
        }
    }).catch(
        error => {
            console.log(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}


export function createFolder(name) {
    return new Promise(result => fetch(config.BASE_URL + "images/userFolder/" + name, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + getTokenFromLocalStorage()
        },
        body: JSON.stringify({name: name})
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            getFolders().then(folders => {
                return result(folders);
            })
        } else {
            alert("Error creating folder");
        }
    }).catch(
        error => {
            console.log(error);
            /*if (error.status === 401) {
                history.replace("/admin-login");
            }*/
        }
    ));
}

export function getFolders() {
    return new Promise(result => fetch(config.BASE_URL + "images/folders", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return result(json)
            });
        } else {
            console.log(response.status)
        }
    }).catch(() => {
        console.log("error")
    }));
}

export function getImagesInFolder(folder) {
    return new Promise(result => fetch(config.BASE_URL + "images/folder-images/" + folder, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    }).then(function (response) {
        if (response.status >= 200 && response.status < 300) {
            response.json().then(json => {
                return result(json)
            });
        } else {
            console.log(response.status)
        }
    }).catch(() => {
        console.log("error")
    }));
}

export function jumpToTop(window) {
    if (typeof window !== "undefined") {
        return window.scrollTo(0, 0);
    }
}

export function changeColor(pathname, setSidebarColor) {
    switch (true) {
        case pathname.includes('stationär'):
            setSidebarColor('#0063af');
            break;
        case pathname.includes('ambulant'):
            setSidebarColor('#f3ac2b');
            break;
        case pathname.includes('stiftung'):
            setSidebarColor('#65b32e');
            break;
        default:
            setSidebarColor('#0063af');
            break;
    }
}

export function getFromLocalStorage(type) {
    let object;
    if (typeof window !== "undefined") {
        if (type === "token")
            object = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
        if (type === "user")
            object = JSON.parse(localStorage.getItem(config.USER_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            if (type === "token")
                return object.token;
            if (type === "user")
                return object.user;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }
}