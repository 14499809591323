import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {
    addAppointment,
    getAppointment,
    showImagesInFolder,
    showUserFolders,
    updateAppointment,
    valueChanged
} from "./util";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import userStore from "../../components/userManagement/userStore";
import ImageCropComponent from "../../components/editImage/ImageCropComponent";
import { getFolders, getImagesInFolder } from "../../helper/util";
import config from "../../config/main.config";
import sharedConfig from "../../../shared/sharedConfig";


const mailRegEx = new RegExp('[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+');

let Editor = null;
if (typeof window !== "undefined") {
    Editor = require("../../components/editText/Editor").default;
}

function cropImage(cropModal, folders, setFolders, setCropModal) {
    if (cropModal) {
        return (
            <ImageCropComponent id={"blog"} //needs to be Blog so the Image Upload works correct!
                cropModal={cropModal}
                folders={folders}
                onClose={() => {
                    getFolders().then(folders => {
                        setFolders(folders);
                    })
                    setCropModal(false);
                }}
            />
        )
    }
}

const CreateAppointment = (props) => {
    const { isNew, onClose, id } = props;
    const [appointment, setAppointment] = useState({});
    const [appointmentErrors, setAppointmentErrors] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [isEditSource, setIsEditSource] = useState(false);
    const [cropModal, setCropModal] = useState(false);
    const [folders, setFolders] = useState([]);
    const [activeFolder, setActiveFolder] = useState("");
    const [images, setImages] = useState([]);



    function checkForErrors() {
        let hasError = false;
        if (isNaN(new Date(appointment.dateStart).getTime())) {
            setAppointmentErrors({
                dateStart: "Datum ungültig",
            })
            hasError = true
        }
        if (isNaN(new Date(appointment.dateEnd).getTime())) {
            setAppointmentErrors({
                dateEnd: "Datum ungültig"
            })
            hasError = true
        }
        if (hasError) {
            return false;
        }
        setAppointmentErrors({});
        return true;
    }

    useEffect(() => {
        getImagesInFolder(activeFolder).then(images =>
            setImages(images)
        )
    }, [cropModal]);

    useEffect(_ => {
        getFolders().then(folders => {
            setFolders(folders);
        })
        if (!isNew) {
            getAppointment(id).then(appointment_ => {
                setAppointment(appointment_);
                setLoaded(true);
            })
        } else {
            setAppointment({
                published: false,
                dateStart: "",
                dateEnd: "",
                name: "",
                location: "",
                fullyBooked: false,
                registerRequired: false,
                contact: "",
                description: ""
            })
            setLoaded(true);
        }

    }, []);

    return (
        <div className={'grid ui'} style={{ paddingTop: '200px', minHeight: '700px' }}>
            <div className={'row centered'}>
                <h1>{isNew ? "Veranstaltung erstellen" : "Veranstaltung bearbeiten"}</h1>
            </div>
            <div className={'row centered'}>
                <div className={'twelve wide column'}>
                    <Button floated={'right'} color={'green'} onClick={_ => {
                        if (checkForErrors()) {
                            if (isNew) {
                                addAppointment(appointment).then(_ => {
                                    onClose(false);
                                })
                            } else {
                                updateAppointment(appointment).then(_ => {
                                    onClose(false);
                                })
                            }
                        } else {
                            console.log("error in Form")
                        }
                    }}>Speichern</Button>
                    <Button floated={'right'} color={'red'} onClick={_ => onClose(false)}>Abbrechen</Button>
                </div>
            </div>
            <div className={'row centered'}>
                <div className={'twelve wide column'}>
                    <Form>
                        <Form.Field>
                            <label>Veröffentlichen</label>
                            <Form.Checkbox
                                indeterminate={false}
                                checked={appointment.published}
                                label={'Veröffentlicht'}
                                disabled={!userStore.isAdmin}
                                onChange={() => {
                                    setAppointment(prev => ({
                                        ...prev,
                                        published: !appointment.published
                                    }))
                                }} />
                        </Form.Field>
                        <Form.Field>
                            <label>Plätze Frei</label>
                            <Form.Checkbox
                                indeterminate={false}
                                checked={!appointment.fullyBooked}
                                label={'Plätze frei'}
                                disabled={!userStore.isAdmin}
                                onChange={() => {
                                    setAppointment(prev => ({
                                        ...prev,
                                        fullyBooked: !appointment.fullyBooked
                                    }))
                                }} />
                        </Form.Field>
                        <Form.Field>
                            <label
                                style={{ margin: "auto", paddingRight: "5px" }}>Anmeldung Erforderlich</label>
                            <Form.Checkbox
                                indeterminate={false}
                                checked={appointment.registerRequired}
                                label={'Anmeldung Erforderlich'}
                                disabled={!userStore.isAdmin}
                                onChange={() => {
                                    setAppointment(prev => ({
                                        ...prev,
                                        registerRequired: !appointment.registerRequired
                                    }))
                                }} />
                        </Form.Field>
                        <Form.Field>
                            <label>Start der Veranstaltung</label>
                            <Form.Input
                                error={appointmentErrors.dateStart}
                                value={appointment.dateStart}
                                placeholder={'Uhrzeit eingeben...'}
                                type={"datetime-local"}
                                onChange={e => valueChanged('dateStart', e, setAppointment)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Ende der Veranstaltung</label>
                            <Form.Input
                                error={appointmentErrors.dateEnd}
                                value={appointment.dateEnd}
                                type={'datetime-local'}
                                placeholder={'Uhrzeit eingeben...'}
                                onChange={e => valueChanged('dateEnd', e, setAppointment)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Titel der Veranstaltung</label>
                            <input
                                value={appointment.name}
                                placeholder={'Titel der Veranstaltung eingeben...'}
                                onChange={e => valueChanged('name', e, setAppointment)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Ort der Veranstaltung</label>
                            <input
                                value={appointment.location}
                                placeholder={'Ort der Veranstaltung eingeben...'}
                                onChange={e => valueChanged('location', e, setAppointment)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Email an die die Anmeldungen gesendet werden</label>
                            <input
                                value={appointment.contact}
                                placeholder={'email eingeben...'}
                                onChange={e => valueChanged('contact', e, setAppointment)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Beschreibung der Veranstaltung</label>
                            <Form.Field
                                style={{ display: "inline-flex" }}>
                                <label
                                    style={{ margin: "auto", paddingRight: "5px" }}>Text als HTML bearbeiten</label>
                                <Form.Radio
                                    toggle={true}
                                    checked={isEditSource}
                                    onChange={() => setIsEditSource(!isEditSource)}>
                                    HTML
                                </Form.Radio>
                            </Form.Field>
                            <div style={{ margin: "5px" }} />
                            <Form.Field>
                                {!isEditSource
                                    ? Editor && loaded &&
                                    <Editor
                                        text={appointment.description}
                                        format={"html"}
                                        updateText={(text) => {
                                            setAppointment(prevState => ({ ...prevState, description: text }));
                                        }} />
                                    : <Form.Field>
                                        <label>HTML:</label>
                                        <TextArea
                                            value={appointment.description}
                                            onChange={e => valueChanged('description', e, setAppointment)}
                                        />
                                    </Form.Field>
                                }
                            </Form.Field>

                        </Form.Field>
                        <Form.Field>
                            <label>Bild der Veranstaltung</label>
                            {appointment.thumbnail &&
                                <picture>
                                    <source
                                        media={"(min-width: 1024px)"}
                                        srcSet={config.BASE_URL_IMAGES + "desktop/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.') || appointment.thumbnail.length) + `.webp ${sharedConfig.IMAGE_SIZES.LARGE}w`
                                            + "," + config.BASE_URL_IMAGES + "desktop/" + appointment.thumbnail} />
                                    <source
                                        media={"(min-width: 321px)"}
                                        srcSet={config.BASE_URL_IMAGES + "tablet/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.') || appointment.thumbnail.length) + `.webp ${sharedConfig.IMAGE_SIZES.MEDIUM}w`
                                            + "," + config.BASE_URL_IMAGES + "tablet/" + appointment.thumbnail} />
                                    <source
                                        media={"(max-width: 320px)"}
                                        srcSet={config.BASE_URL_IMAGES + "mobile/" + appointment.thumbnail.slice(0, appointment.thumbnail.lastIndexOf('.') || appointment.thumbnail.length) + `.webp ${sharedConfig.IMAGE_SIZES.SMALL}w`
                                            + "," + config.BASE_URL_IMAGES + "mobile/" + appointment.thumbnail} />
                                    <img
                                        className={'articleThumbnail'}
                                        src={config.BASE_URL_IMAGES + "all-images/" + appointment.thumbnail}
                                        alt={"Veranstaltungstitelbild"}
                                    />
                                </picture>}
                            <div className={'column'}>
                                <div className="no-padding thumbnail-form">
                                    <label htmlFor="file">
                                        <b>Bild Hochladen</b>
                                    </label>
                                    <br />
                                    <Button className={"admin-button-primary"}
                                        onClick={() => setCropModal(true)}
                                    >Hochladen</Button>
                                </div>
                            </div>
                        </Form.Field>
                    </Form>
                    <div className={'row'}>
                        <div className={'column'}>
                            <b>Ordner<br /></b>
                            {showUserFolders(folders, setActiveFolder, setImages)}
                            {showImagesInFolder(images, activeFolder, setAppointment)}

                        </div>
                    </div>
                    {cropImage(cropModal, folders, setFolders, setCropModal)}
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(CreateAppointment)